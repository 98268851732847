import React, { useContext, useEffect, useState } from 'react'
import joi from "joi"
import axios from 'axios'
import { NewsContext } from '../../context/news.js'



export default function News() {


  let { AllNewsArr, setUpdateCount } = useContext(NewsContext);
  let [isLoading, setIsLoading] = useState(false);
  const [newsArr, setNewsArr] = useState([]);
  let [err, setErr] = useState("")
  let [errList, setErrorList] = useState([])
  // console.log({ AllNewsArr });
  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  // const [updateCount, setUpdateCount] = useState(0);
  const token = localStorage.getItem("token")

  useEffect(() => {
    if (AllNewsArr && AllNewsArr.news) {
      setNewsArr(AllNewsArr.news);
    }
  }, [AllNewsArr])



  let [news, setNews] = useState({

    title: "",
    description: "",

  });


  async function postApi(news) {
    try {
      // const postData = new FormData();
      // postData.append('title', news.title);
      // postData.append('description', news.description);

      // console.log({news});
      const response = await axios.post(`${process.env.REACT_APP_BACK_URL}/admin/news`, news, {
        headers: {
          'Content-Type': 'application/json', // Set the content type for FormData
          'authorization': `${process.env.REACT_APP_BEARER_TOKEN}${token}`
        },
      });
      // console.log({response});
      if (response.data.message === 'success') {
        setErr('');
        setIsLoading(false);
        handleHideAddModal(); // Close the modal on successful submission
        setUpdateCount((prevCount) => prevCount + 1);
      } else {
        setIsLoading(false);
        setErr(response.data.message);
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching data:', error);
      setErrorList(error.response?.data?.validationError || []);
      setErr(error.response?.data?.message || 'An error occurred');
    }
  }



  async function putApi(news) {
    try {
      // console.log({ news });
      const postData = new FormData();

      postData.append('title', news.title);
      postData.append('description', news.description);

      // console.log({ postData });

      const response = await axios.put(`${process.env.REACT_APP_BACK_URL}/admin/news/${news._id}`, postData, {
        headers: {
          'Content-Type': 'application/json',
          'authorization': `${process.env.REACT_APP_BEARER_TOKEN}${token}`
        },
      });

      if (response.data.message === 'success') {
        setErr('');
        setIsLoading(false);
        handleHideUpdateModal(); // Close the modal on successful submission
        setUpdateCount((prevCount) => prevCount + 1);
      } else {
        setIsLoading(false);
        setErr(response.data.message);
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching data:', error);
      setErrorList(error.response?.data?.validationError || []);
      setErr(error.response?.data?.message || 'An error occurred');
    }
  }


  async function deleteApi(id) {

    try {
      const response = await axios.delete(`${process.env.REACT_APP_BACK_URL}/admin/news/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'authorization': `${process.env.REACT_APP_BEARER_TOKEN}${token}`
        },
        // You can include a request body for DELETE if necessary
        // body: JSON.stringify({}),
      });

      if (response.status === 204) {
        // Deletion successful
        setErr("")
        
        setShowDeleteModal(false);
        // You can perform additional actions or update UI as needed
        setUpdateCount((prevCount) => prevCount + 1);
      } else {
        
        setErr(response.data.message);
      }
    } catch (error) {
      
      console.error('Error fetching data:', error);
      setErrorList(error.response?.data?.validationError || []);
      setErr(error.response?.data?.message || 'An error occurred');
    }

  }



  //  getNewsData --------------------------------------------------------------------


  function getNewsData(e) {

    const { name, value } = e.target;
    // console.log({files});
    setNews({
      ...news,
      [name]: value,
    });

    // console.log({ news });

  }


  //  form submitions --------------------------------------------------------------------

  function submitForm(e) {
    e.preventDefault();
    setIsLoading(true);
    // Perform any additional validation or processing before calling postApi
    let validationResult = newsValidation();
    // console.log({ validationResult });
    if (validationResult.error) {
      setIsLoading(false);
      setErrorList(validationResult.error.details);
    } else {
      setErrorList([]);
      postApi(news);
    }
  }




  function updateForm(e) {
    e.preventDefault();
    setIsLoading(true);
    // Perform any additional validation or processing before calling postApi
    let validationResult = newsUpdateValidation();

    if (validationResult.error) {
      setIsLoading(false);
      setErrorList(validationResult.error.details);
    } else {
      setErrorList([]);
      // console.log("ok");
      putApi(news);
    }
  }

  // Validation functions  -----------------------------------------


  function newsValidation() {
    const schema = joi.object({

      title: joi.string().min(1).max(500).required(),
      description: joi.string().allow('').max(15000),

    });

    return schema.validate(news, { abortEarly: true });

  }




  function newsUpdateValidation() {
    const schema = joi.object({
      _id: joi.string(),
      title: joi.string().min(2).max(500),
      description: joi.string().allow('').max(15000),

    });

    return schema.validate(news, { abortEarly: true });

  }





  //  handle Modal -------------------------------------------------------------

  const handleShowAddModal = () => setShowAddModal(true);
  const handleHideAddModal = () => {
    setShowAddModal(false);
    setErr("");
    setErrorList([]);
  }
  const handleHideUpdateModal = () => {
    setShowUpdateModal(false);
    setErr("");
    setNews({
      title:'',
      description:'',
    });
    setErrorList([]);
  }
  const handleShowUpdateModal = (item) => {
    setSelectedItem(item);
    setShowUpdateModal(true);
    // console.log({ item });

    // Set the initial state with the selected item's data
    setNews({
      _id: item._id || '',
      title: item.title || '',
      description: item.description || '',
    });
  };

  const handleAddItem = () => {
    if (errList.length == 0 && err != "") {
      handleHideAddModal();
    }
  };

  const handleUpdateItem = () => {
    if (errList.length == 0 && err != "") {
      handleHideUpdateModal();
    }
  };


  const filteredNews = newsArr.filter((item) =>
    item.title && item.title.toLowerCase().includes(searchTerm?.toLowerCase())
  );





  //  handle Delete news -------------------------------------------------------------

  const handleDeleteConfirmation = (id) => {
    setItemToDelete(id);
    setShowDeleteModal(true);
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setItemToDelete(null);
  };

  const handleDeleteConfirm = () => {
    if (itemToDelete) {

      deleteApi(itemToDelete);

      setShowDeleteModal(false);
      setItemToDelete(null);
    }
  };





  return (

    <div className="container mt-5">
      <div className="d-flex justify-content-between mb-3">
        <input
          type="text"
          placeholder="Search..."
          className="form-control w-75"
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button className="btn btn-dark" onClick={handleShowAddModal}>
          <i className="fa-solid fa-plus"></i> Add News
        </button>

      </div>

      <table className="table table-striped">
        <thead>
          <tr>
            <th></th>
            <th className='w-10'>Date</th>
            <th >Title</th>
            <th >Description</th>
            <th></th>

          </tr>
        </thead>
        <tbody>
          {filteredNews.map((item, index) => (
            <tr key={item._id} >
              <td>{index + 1}</td>
              <td>{item?.createdAt.split("T")[0]}</td>
              <td>{item.title}</td>
              <td>{item.description}</td>


              <td >
                <button
                  className="btn mr-2"
                  onClick={() => handleShowUpdateModal(item)}
                >
                  <i className="fa-regular fa-pen-to-square"></i>
                </button>
                <button
                  className="btn "
                  onClick={() => handleDeleteConfirmation(item._id)}
                >
                  <i className="fa-solid fa-trash" style={{ color: "#ca4e4e" }}></i>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Add Modal */}
      <form onSubmit={submitForm}>
        <div
          className={`modal fade ${showAddModal ? 'show' : ''}`}
          style={{ display: showAddModal ? 'block' : 'none' }}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden={!showAddModal}
        >

          <div className="overlay">
            <div className=" w-50" role="document">

              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Add News
                  </h5>
                  <button
                    type="button"
                    className="btn bg-danger"
                    onClick={handleHideAddModal}
                  >
                    <i className="fa-solid fa-xmark" style={{ color: "#fff" }}></i>
                  </button>
                </div>
                <div className="modal-body">
                  {err.length > 0 ? <p className='alert alert-danger '>{err}</p> : ""}
                  {errList?.map((item, index) => <p className='alert alert-danger' key={index}>{item.message}</p>)}

                  <div className="row">
                    <div className="col-md-12">
                      <label htmlFor="title">Title</label>
                      <input type="text" id='title' name='title' onChange={getNewsData} className='form-control my-2' />
                    </div>
                  </div>

                  <label htmlFor="description">Description</label>
                  <textarea type="text" id='description' name='description' onChange={getNewsData} className='form-control my-2' />

                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleHideAddModal}
                  >
                    Close
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={handleAddItem}
                  >
                    {isLoading == true ? <i className='fas fa-spinner fa-spin'></i> : "Add News"}
                  </button>
                </div>
              </div>
            </div>
          </div>

        </div>

      </form>
      {/* Update Modal */}



      <form onSubmit={updateForm}>
        <div
          className={`modal fade ${showUpdateModal ? 'show' : ''}`}
          style={{ display: showUpdateModal ? 'block' : 'none' }}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden={!showUpdateModal}
        >
          <div className="overlay">
            <div className=" w-50" role="document">

              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Update News
                  </h5>
                  <button
                    type="button"
                    className="btn bg-danger"
                    onClick={handleHideUpdateModal}
                  >
                    <i className="fa-solid fa-xmark" style={{ color: "#fff" }}></i>
                  </button>
                </div>
                <div className="modal-body">
                  {err?.length > 0 ? <p className='alert alert-danger '>{err}</p> : ""}
                  {errList?.map((item, index) => <p className='alert alert-danger' key={index}>{item.message}</p>)}

                  <div className="row">
                    <div className="col-md-12">
                      <label htmlFor="title">Title</label>
                      <input type="text" id='title' name='title' value={news.title} onChange={getNewsData} className='form-control my-2' />
                    </div>
                  </div>
                  <label htmlFor="description">Description</label>
                  <textarea type="text" id='description' name='description' value={news.description} onChange={getNewsData} className='form-control my-2' />


                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleHideUpdateModal}
                  >
                    Close
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={handleUpdateItem}
                  >
                 
                    {isLoading == true ? <i className='fas fa-spinner fa-spin'></i> : "Update News"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>


      {/* Delete Confirmation Modal */}
      <div
        className={`modal fade ${showDeleteModal ? 'show' : ''}`}
        style={{ display: showDeleteModal ? 'block' : 'none' }}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="deleteConfirmationModalLabel"
        aria-hidden={!showDeleteModal}
      >
        <div className="overlay">
          <div className="w-25" role="document">
            <div className="modal-content">
              {err.length > 0 ? <p className='alert alert-danger '>{err}</p> : ""}
              {errList?.map((item, index) => <p className='alert alert-danger' key={index}>{item.message}</p>)}
              <div className="modal-header">
                <h5 className="modal-title" id="deleteConfirmationModalLabel">
                  Delete Confirmation
                </h5>
                <button
                  type="button"
                  className="btn bg-danger"
                  onClick={handleDeleteCancel}
                >
                  <i className="fa-solid fa-xmark" style={{ color: '#fff' }}></i>
                </button>
              </div>
              <div className="modal-body">
                <p>Are you sure you want to delete this News?</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleDeleteCancel}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-danger"
                  onClick={handleDeleteConfirm}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div >
  );
};


