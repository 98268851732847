import { createContext, useEffect, useState } from "react";
import axios from "axios";

export let BrandsContext = createContext('');



function BrandsContextProvider(props){
    const [AllBrandsArr,setAllBrandsArr] = useState([]);
    const [updateCount, setUpdateCount] = useState(0);
    async function getAllBrands(){
        try {
            const {data}=await axios.get(`${process.env.REACT_APP_BACK_URL}/admin/brand`);
            // console.log({data});
            setAllBrandsArr(data)
        } catch (error) {
            console.log('Error fetching data:', error);
        }
       
    }
    useEffect(()=>{
        getAllBrands()
    },[updateCount])

    return <BrandsContext.Provider value={{AllBrandsArr,setUpdateCount}}>
       {props.children}
    </BrandsContext.Provider>
}

export default BrandsContextProvider;