import React, { useState } from 'react'
import joi from 'joi';
import axios from 'axios';
import style from "./login.module.css"
import { useNavigate } from 'react-router-dom';
import { BsEye, BsEyeSlash } from 'react-icons/bs';

export default function Login({ saveCurrentUser }) {
    // console.log({props});
    const [showPassword, setShowPassword] = useState(false);
    let navigate = useNavigate();
    let [validationErr, setValidationErr] = useState([])
    let [err, setErr] = useState("")
    let [isLoading, setIsLoading] = useState(false);
    let [user, setUser] = useState({
        email: "",
        password: "",
    });

    function getData(e) {
        let loginUser = { ...user }
        loginUser[e.target.name] = e.target.value;
        // console.log(e.target.value);
        setUser(loginUser);
    }


    function submit(e) {
        e.preventDefault();
        setIsLoading(true);
        let checkValidation = validation();

        // console.log(checkValidation);
        if (checkValidation?.error) {
            let { details } = checkValidation.error;
            setValidationErr(details);
        }
        else {
            setValidationErr([]);
            requestApi(user);
            // console.log("dadasd");
        }


    }
    function togglePasswordVisibility() {
        setShowPassword(!showPassword);
    }
    async function requestApi(user) {

        try {
            let { data } = await axios.post(`${process.env.REACT_APP_BACK_URL}/admin/auth/login`, user);

            // console.log({ data });

            if (data.message === "success") {
                setErr("");
                // console.log(data);
                setIsLoading(false);
                localStorage.setItem('token', data.access_token);
                saveCurrentUser();
                navigate('/event');
            } else {
                setIsLoading(false);
                setErr('In-correct email or password');
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                // Handle AxiosError
                setIsLoading(false);
                setErr('In-correct email or password');
                //   setErr(error.response.data);
                //   setErr(error.response.status);
            } else {
                // Handle other errors
                setIsLoading(false);
                setErr(error.message);
            }
        }

    }

    function validation() {
        let schema = joi.object({
            email: joi.string().required(),
            password: joi.string().required(),
        });

        return schema.validate(user, { abortEarly: false });
    }






    return (
        <div className={`pt-5 d-flex justify-content-center align-items-center bg-black`} style={{ height: "100vh" }}>

            <div className={`loginForm ${style['login-width']}`}>
                {err ? <p className='alert alert-danger'>{err}</p> : ""}
                {validationErr.map((item, index) => <p className='alert alert-danger' key={index}>{item.message}</p>)}
                <form onSubmit={submit}>
                    {/* <h2>Login</h2> */}
                    <div className='d-flex justify-content-center mb-4'>
                        <img src={process.env.PUBLIC_URL + '/G-Native.svg'} alt="GoNative Logo"></img>
                    </div>
                    <label htmlFor="email" className={`${style['orange']}`}>Email</label>
                    <input className='form-control mt-2' type="text" name="email" id="email" onChange={getData} />
                    <label htmlFor="password" className={`${style['orange']} mt-2`}>Password</label>
                    <div className="input-group mt-2">
                        <input
                            className='form-control '
                            type={showPassword ? 'text' : 'password'}
                            name="password"
                            id="password"
                            onChange={getData}
                        />
                       
                            <span className="input-group-text bg-black border-0">
                                {showPassword ? (
                                    <BsEyeSlash className="orange" onClick={togglePasswordVisibility} />
                                ) : (
                                    <BsEye className="orange" onClick={togglePasswordVisibility} />
                                )}
                            </span>
                        
                    </div>
                    <div className={`d-flex justify-content-center`}>
                        <button className={`btn ${style['bg-orange']} text-white mt-4 w-100 `}>
                            {isLoading == true ? <i className='fas fa-spinner fa-spin'></i> : "Login"}</button>
                    </div>

                </form>

            </div>
        </div>
    )
}



