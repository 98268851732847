import { createContext, useEffect, useState } from "react";
import axios from "axios";

export let NewsContext = createContext('');



function NewsContextProvider(props){
    const [AllNewsArr,setAllNewsArr] = useState([]);
    const [updateCount, setUpdateCount] = useState(0);
    async function getAllNews(){
        try {
            const {data}=await axios.get(`${process.env.REACT_APP_BACK_URL}/admin/news`);
            // console.log({data});
            if(data.news){
                data.news.reverse();
            }
            setAllNewsArr(data)
        } catch (error) {
            console.log('Error fetching data:', error);
        }
       
    }
    useEffect(()=>{
        getAllNews()
    },[updateCount])

    return <NewsContext.Provider value={{AllNewsArr,setUpdateCount}}>
       {props.children}
    </NewsContext.Provider>
}

export default NewsContextProvider;