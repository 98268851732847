import React, { useContext, useEffect, useState } from 'react'
import joi from "joi"
import axios from 'axios'
import { subscriberContext } from '../../context/subscriber.js';



export default function Subscriber(props) {


  let { AllSubscriberArr ,setUpdateCount} = useContext(subscriberContext);
  const [subscribersArr, setSubscribersArr] = useState([]);
  let [err, setErr] = useState("")
  let [errList, setErrorList] = useState([])
  // console.log({ AllBrandsArr });
  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  // const [updateCount, setUpdateCount] = useState(0);

 
  const token=localStorage.getItem("token");

  useEffect(() => {
    if (AllSubscriberArr && AllSubscriberArr.subscribers) {
      setSubscribersArr(AllSubscriberArr.subscribers);
    }
  }, [AllSubscriberArr])



  let [subscriber, setSubscriber] = useState({

    email: "",

  });


  // console.log({subscribersArr});

  async function postApi(subscriber) {
    try {
      // const postData = new FormData();
      // postData.append('email', subscriber.email);
     
      // console.log({postData});
      const response = await axios.post(`${process.env.REACT_APP_BACK_URL}/admin/subscriber`,subscriber, {
        headers: {
          'Content-Type': 'application/json', // Set the content type for FormData
          'authorization': `${process.env.REACT_APP_BEARER_TOKEN}${token}`        },
      });
      // console.log({response});
      if (response.data.message === 'success') {
        setErr('');
        handleHideAddModal(); // Close the modal on successful submission
        setUpdateCount((prevCount) => prevCount + 1);
      } else {
        setErr(response.data.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setErrorList(error.response?.data?.validationError || []);
      setErr(error.response?.data?.message || 'An error occurred');
    }
  }



  // async function putApi(subscriber) {
  //   try {
  //     const postData = new FormData();
  //     postData.append('email', subscriber.email);

  //     // console.log({ postData });

  //     const response = await axios.put(`http://localhost:5000/admin/subscriber/${subscriber._id}`, postData, {
  //       headers: {
  //         'Content-Type': 'multipart/form-data',
  //         'authorization': `${process.env.REACT_APP_BEARER_TOKEN}${token}`        },
  //     });

  //     if (response.data.message === 'success') {
  //       setErr('');
  //       handleHideUpdateModal(); // Close the modal on successful submission
  //       setUpdateCount((prevCount) => prevCount + 1);
  //     } else {
  //       setErr(response.data.message);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //     setErrorList(error.response?.data?.validationError || []);
  //     setErr(error.response?.data?.message || 'An error occurred');
  //   }
  // }


  async function deleteApi(id) {

    try {
      const response = await axios.delete(`${process.env.REACT_APP_BACK_URL}/admin/subscriber/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'authorization': `${process.env.REACT_APP_BEARER_TOKEN}${token}`
        },
        // You can include a request body for DELETE if necessary
        // body: JSON.stringify({}),
      });

      if (response.status === 204) {
        // Deletion successful
        setErr("")
        setShowDeleteModal(false);
        // You can perform additional actions or update UI as needed
        setUpdateCount((prevCount) => prevCount + 1);
      } else {
        setErr(response.data.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setErrorList(error.response?.data?.validationError || []);
      setErr(error.response?.data?.message || 'An error occurred');
    }

  }



  //  getBrandData --------------------------------------------------------------------


  function getSubscriberData(e) {

    const { name, value } = e.target;
    // console.log({files});
  
    setSubscriber({
      ...subscriber,
      [name]: value,
    });

    // console.log({ brand });

  }


  //  form submitions --------------------------------------------------------------------

  function submitForm(e) {
    e.preventDefault();

    // Perform any additional validation or processing before calling postApi
    let validationResult = newsValidation();
    // console.log({ validationResult });
    if (validationResult.error) {
      setErrorList(validationResult.error.details);
    } else {
      setErrorList([]);
      postApi(subscriber);
    }
  }




  // function updateForm(e) {
  //   e.preventDefault();

  //   // Perform any additional validation or processing before calling postApi
  //   let validationResult = newsUpdateValidation();

  //   if (validationResult.error) {
  //     setErrorList(validationResult.error.details);
  //   } else {
  //     setErrorList([]);
  //     // console.log("ok");
  //     putApi(subscriber);
  //   }
  // }

  // Validation functions  -----------------------------------------


  function newsValidation() {
    const schema = joi.object({
      email: joi.string().email({
        minDomainSegments: 2,
        maxDomainSegments: 4,
        tlds: { allow: ['com', 'net'] }
    }).required(),
    });

    return schema.validate(subscriber, { abortEarly: true });

  }




  function newsUpdateValidation() {
    const schema = joi.object({
      _id: joi.string(),
      email: joi.string().email({
        minDomainSegments: 2,
        maxDomainSegments: 4,
        tlds: { allow: ['com', 'net'] }
    }).required(),

    });

    return schema.validate(subscriber, { abortEarly: true });

  }





  //  handle Modal -------------------------------------------------------------

  const handleShowAddModal = () => setShowAddModal(true);
  const handleHideAddModal = () => {
    setShowAddModal(false);
    setErr("");
    setErrorList([]);
  }
  const handleHideUpdateModal = () => {
    setShowUpdateModal(false);
    setErr("");
    setErrorList([]);
  }
  const handleShowUpdateModal = (item) => {
    setSelectedItem(item);
    setShowUpdateModal(true);
    // console.log({ item });

    // Set the initial state with the selected item's data
    setSubscriber({
      _id: item._id || '',
      email: item.email || '',
    });
  };

  const handleAddItem = () => {
    if (errList.length == 0 && err != "") {
      handleHideAddModal();
    }
  };

  const handleUpdateItem = () => {
    if (errList.length == 0 && err != "") {
      handleHideUpdateModal();
    }
  };


  const filteredSubscriber = subscribersArr.filter((item) =>
    item.email && item.email.toLowerCase().includes(searchTerm?.toLowerCase())
  );





  //  handle Delete Event -------------------------------------------------------------

  const handleDeleteConfirmation = (id) => {
    // console.log({id});
    setItemToDelete(id);
    setShowDeleteModal(true);
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setItemToDelete(null);
  };

  const handleDeleteConfirm = () => {
    if (itemToDelete) {

      deleteApi(itemToDelete);
      setShowDeleteModal(false);
      setItemToDelete(null);
    }
  };





  return (

    <div className="container mt-5">
      <div className="d-flex justify-content-between mb-3">
        <input
          type="text"
          placeholder="Search..."
          className="form-control w-75"
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button className="btn btn-dark" onClick={handleShowAddModal}>
          <i className="fa-solid fa-plus"></i> Add Subscriber
        </button>

      </div>

      <table className="table table-striped">
        <thead>
          <tr>
            <th></th>
            <th>Subscriber Email</th>
            <th></th>

          </tr>
        </thead>
        <tbody>
          {filteredSubscriber.map((item, index) => (
            <tr key={item._id} >
              <td>{index + 1}</td>
              <td>{item.email}</td>
              <td >
                {/* <button
                  className="btn mr-2"
                  onClick={() => handleShowUpdateModal(item)}
                >
                  <i className="fa-regular fa-pen-to-square"></i>
                </button> */}
                <button
                  className="btn "
                  onClick={() => handleDeleteConfirmation(item._id)}
                >
                  <i className="fa-solid fa-trash" style={{ color: "#ca4e4e" }}></i>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Add Modal */}
      <form onSubmit={submitForm}>
        <div
          className={`modal fade ${showAddModal ? 'show' : ''}`}
          style={{ display: showAddModal ? 'block' : 'none' }}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden={!showAddModal}
        >

          <div className="overlay">
            <div className=" w-50" role="document">

              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Add Subscriber
                  </h5>
                  <button
                    type="button"
                    className="btn bg-danger"
                    onClick={handleHideAddModal}
                  >
                    <i className="fa-solid fa-xmark" style={{ color: "#fff" }}></i>
                  </button>
                </div>
                <div className="modal-body">
                  {err.length > 0 ? <p className='alert alert-danger '>{err}</p> : ""}
                  {errList?.map((item, index) => <p className='alert alert-danger' key={index}>{item.message}</p>)}

                  <div className="row">
                    <div className="col-md-12">
                      <label htmlFor="email">Subscriber Email</label>
                      <input type="text" id='email' name='email' onChange={getSubscriberData} className='form-control my-2' />
                    </div>
                  </div>

                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleHideAddModal}
                  >
                    Close
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={handleAddItem}
                  >
                    Add Subscriber
                  </button>
                </div>
              </div>
            </div>
          </div>

        </div>

      </form>
      {/* Update Modal */}



      {/* <form onSubmit={updateForm}>
        <div
          className={`modal fade ${showUpdateModal ? 'show' : ''}`}
          style={{ display: showUpdateModal ? 'block' : 'none' }}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden={!showUpdateModal}
        >
          <div className="overlay">
            <div className=" w-50" role="document">

              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Update Subscriber
                  </h5>
                  <button
                    type="button"
                    className="btn bg-danger"
                    onClick={handleHideUpdateModal}
                  >
                    <i className="fa-solid fa-xmark" style={{ color: "#fff" }}></i>
                  </button>
                </div>
                <div className="modal-body">
                  {err?.length > 0 ? <p className='alert alert-danger '>{err}</p> : ""}
                  {errList?.map((item, index) => <p className='alert alert-danger' key={index}>{item.message}</p>)}

                  <div className="row">
                    <div className="col-md-12">
                      <label htmlFor="email">Subscriber Email</label>
                      <input type="text" id='email' name='email' value={subscriber.email} onChange={getSubscriberData} className='form-control my-2' />
                    </div>
                  </div>

                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleHideUpdateModal}
                  >
                    Close
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={handleUpdateItem}
                  >
                    Update Brand
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form> */}


      {/* Delete Confirmation Modal */}
      <div
        className={`modal fade ${showDeleteModal ? 'show' : ''}`}
        style={{ display: showDeleteModal ? 'block' : 'none' }}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="deleteConfirmationModalLabel"
        aria-hidden={!showDeleteModal}
      >
        <div className="overlay">
          <div className="w-25" role="document">
            <div className="modal-content">
              {err.length > 0 ? <p className='alert alert-danger '>{err}</p> : ""}
              {errList?.map((item, index) => <p className='alert alert-danger' key={index}>{item.message}</p>)}
              <div className="modal-header">
                <h5 className="modal-title" id="deleteConfirmationModalLabel">
                  Delete Confirmation
                </h5>
                <button
                  type="button"
                  className="btn bg-danger"
                  onClick={handleDeleteCancel}
                >
                  <i className="fa-solid fa-xmark" style={{ color: '#fff' }}></i>
                </button>
              </div>
              <div className="modal-body">
                <p>Are you sure you want to delete this Subscriber?</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleDeleteCancel}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-danger"
                  onClick={handleDeleteConfirm}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div >
  );
};


