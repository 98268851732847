import React from 'react'
import { NavLink } from 'react-router-dom'

export default function Sidebar({ logOut }) {
    return (
        <div className="d-flex flex-column flex-shrink-0 p-3 text-white bg-dark h-100" style={{ minHeight: "100vh" }}>
            <NavLink className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none">
                <img src={process.env.PUBLIC_URL + '/G-Native.svg'} alt="GoNative Logo" width={40} height={40} className='ms-3'/>
                <span className="fs-4 ms-3"> Go-Native</span>
            </NavLink>
            <hr />
            <ul className="nav nav-pills flex-column mb-auto">
                <li className="nav-item">
                    <NavLink to={"event"} className="nav-link text-white" aria-current="page">

                        <i className="fa-regular fa-calendar-days bi me-2" style={{ color: "#ffffff" }}></i>
                        Events/Workshops
                    </NavLink>
                </li>
                <li>
                    <NavLink to={"brand"} className="nav-link text-white">
                        <i className="fa-solid fa-store bi me-2" style={{ color: "#ffffff" }}></i>
                        Brands
                    </NavLink>
                </li>
                <li>
                    <NavLink to={"news"} className="nav-link text-white">
                        <i className="fa-solid fa-newspaper bi me-2" style={{ color: "#ffffff" }}></i>
                        News
                    </NavLink>
                </li>
                {/* <li>
                    <NavLink to={"contact"} className="nav-link text-white">
                        <i className="fa-solid fa-message bi me-2" style={{ color: "#ffffff" }}></i>
                        Contacts
                    </NavLink>
                </li> */}
                <li>
                    <NavLink to={"subscriber"} className="nav-link text-white">
                        <i className="fa-solid fa-users bi me-2" style={{ color: "#ffffff" }}></i>
                        Subscribers
                    </NavLink>
                </li>
            </ul>
            <hr />
            <div className="dropdown">
                <NavLink className="d-flex align-items-center text-white text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
                    {/* <img src="https://github.com/mdo.png" alt="" width="32" height="32" className="rounded-circle me-2" /> */}
                    <strong>Admin</strong>
                </NavLink>
                <ul className="dropdown-menu dropdown-menu-dark text-small shadow" aria-labelledby="dropdownUser1">

                    {/* <li><Link className="dropdown-item" >Settings</Link></li> */}
                    {/* <li><NavLink className="dropdown-item" to={"profile"}>Profile</NavLink></li>
                    <li><hr className="dropdown-divider" /></li> */}
                    <li className="dropdown-item" onClick={logOut}>  Sign out</li>
                </ul>
            </div>
        </div>
    )
}
