import { createContext, useEffect, useState } from "react";
import { Navigate} from 'react-router-dom'
import axios from "axios";

export let subscriberContext = createContext('');

const token = localStorage.getItem("token");


function SubscriberContextProvider(props) {
    const [AllSubscriberArr, setAllSubscriberArr] = useState([]);
    const [updateCount, setUpdateCount] = useState(0);
    
    async function getAllSubscribers() {
        try {
            if(token){
                const { data } = await axios.get(`${process.env.REACT_APP_BACK_URL}/admin/subscriber`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': `${process.env.REACT_APP_BEARER_TOKEN}${token}`,
                    },
                });
                setAllSubscriberArr(data)
            }
            
        } catch (error) {
            const { response } = error;
            // console.log(response.data.message);
            if (response.data.message === "TokenExpiredError: jwt expired") {
                localStorage.removeItem("token");
                <Navigate to={"/Root"}/>
            }
            console.log('Error fetching data:', error);
        }

    }
    useEffect(() => {
        getAllSubscribers()
    }, [updateCount])

    return <subscriberContext.Provider value={{ AllSubscriberArr,setUpdateCount}}>
        {props.children}
    </subscriberContext.Provider>
}

export default SubscriberContextProvider;