import { createContext, useEffect, useState } from "react";
import axios from "axios";

export let EventContext = createContext('');



function EventContextProvider(props){
    const [AllEventsArr,setAllEventsArr] = useState([]);
    const [updateCount, setUpdateCount] = useState(0);
    async function getAllEvents(){
        try {
            const {data}=await axios.get(`${process.env.REACT_APP_BACK_URL}/admin/event`);
            // console.log({data});

            if(data.events){
                data.events.reverse();
            }
            setAllEventsArr(data)
        } catch (error) {
            console.log('Error fetching data:', error);
        }
       
    }
    useEffect(()=>{
        getAllEvents()
    },[updateCount])

    return <EventContext.Provider value={{AllEventsArr,setUpdateCount}}>
       {props.children}
    </EventContext.Provider>
}

export default EventContextProvider;