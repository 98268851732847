import React from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import Sidebar from './components/Sidebar/Sidebar.jsx'
import Login from './components/Login/Login.jsx'

export default function Root({ user , setUser}) {

    let navigate = useNavigate()

    function logOut(){
        localStorage.removeItem("token");
        setUser(null);
        navigate('/login ')
    }


    return (
        <div style={{ minHeight: "100vh" }}>
            {user ?
                <div className="row" >
                    <div className="col-md-3">
                        <Sidebar logOut={logOut}></Sidebar>
                    </div>
                    <div className="col-md-9">
                        <Outlet logOut={logOut}></Outlet>
                    </div>

                </div> :
                
                    <Outlet/>
           

            }


        </div>
    )
}
