import React, { useContext, useEffect, useState } from 'react'
import { EventContext } from '../../context/event.js'
import joi from "joi"
import axios from 'axios'


export const generalFields = {
  email: joi.string().email({
    minDomainSegments: 2,
    maxDomainSegments: 4,
    tlds: { allow: ['com', 'net'] }
  }).required(),
  password: joi.string().pattern(new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/)).required(),
  cPassword: joi.string().required(),
  phone: joi.string().pattern(new RegExp(/^01[0125][0-9]{8}$/)),
  gender: joi.string()
    .valid('Male', 'Female')
    .required(),
  address: joi.string(),
  file: joi.object()
}



export default function Event() {


  let { AllEventsArr, setUpdateCount } = useContext(EventContext);
  let [isLoading, setIsLoading] = useState(false);
  const [events, setEvents] = useState([]);
  let [err, setErr] = useState("")
  let [errList, setErrorList] = useState([])
  // console.log({ AllEventsArr });
  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  // const [updateCount, setUpdateCount] = useState(0);

  const token = localStorage.getItem("token")

  useEffect(() => {
    if (AllEventsArr && AllEventsArr.events) {
      setEvents(AllEventsArr.events);
    }
  }, [AllEventsArr])



  let [event, setEvent] = useState({

    title: "",
    subTitle: "",
    description: "",
    startDate: "",
    endDate: "",
    mainImage: [],
    subImage: [],
    type: "",
    isFeatured: false,

  });


  async function postApi(event) {
    try {
      const postData = new FormData();
      postData.append('title', event.title);
      postData.append('subTitle', event.subTitle);
      postData.append('description', event.description);
      postData.append('startDate', event.startDate);
      postData.append('endDate', event.endDate);
      postData.append('type', event.type);
      postData.append('isFeatured', event.isFeatured);

      event.mainImage.forEach((mainImage) => {
        postData.append('mainImage', mainImage);
      });

      if (event.subImage[0]?.length > 0) {
        Object.values(event.subImage[0]).forEach((subImage) => {
          console.log({ subImage });
          postData.append('subImage', subImage);
        });
      }


      const response = await axios.post(`${process.env.REACT_APP_BACK_URL}/admin/event`, postData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Set the content type for FormData
          'authorization': `${process.env.REACT_APP_BEARER_TOKEN}${token}`, // Add your authorization token
        },
      });

      if (response.data.message === 'success') {
        setErr('');
        setIsLoading(false);
        handleHideAddModal(); // Close the modal on successful submission
        setUpdateCount((prevCount) => prevCount + 1);
        setEvent((prevEvent) => ({
          ...prevEvent,
          title: "",
          subTitle: "",
          description: "",
          startDate: "",
          endDate: "",
          mainImage: [],
          subImage: [],
          type: "",
          isFeatured: false,
        }));
      } else {
        setIsLoading(false);
        setErr(response.data.message);
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching data:', error);
      setErrorList(error.response?.data?.validationError || []);
      setErr(error.response?.data?.message || 'An error occurred');

    }
  }



  async function putApi(event) {
    try {
      // console.log({ event });
      const postData = new FormData();

      postData.append('title', event.title);
      postData.append('subTitle', event.subTitle);
      postData.append('description', event.description);
      postData.append('startDate', event.startDate);
      postData.append('endDate', event.endDate);
      postData.append('type', event.type);
      postData.append('isFeatured', event.isFeatured);

      if (event.mainImage && event.mainImage.length > 0) {
        event.mainImage.forEach((mainImage) => {
          postData.append('mainImage', mainImage);
        });
      }

      if (event.subImage[0]?.length > 0) {
        Object.values(event.subImage[0]).forEach((subImage) => {
          postData.append('subImage', subImage);
        });
      }

      // console.log({ postData });

      const response = await axios.put(`${process.env.REACT_APP_BACK_URL}/admin/event/${event._id}`, postData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'authorization': `${process.env.REACT_APP_BEARER_TOKEN}${token}`, // Add your authorization token
        },
      });

      if (response.data.message === 'success') {
        setErr('');
        setIsLoading(false);
        handleHideUpdateModal(); // Close the modal on successful submission
        setUpdateCount((prevCount) => prevCount + 1);
      } else {
        setIsLoading(false);
        setErr(response.data.message);
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching data:', error);
      setErrorList(error.response?.data?.validationError || []);
      setErr(error.response?.data?.message || 'An error occurred');
    }
  }


  async function deleteApi(id) {

    try {
      const response = await axios.delete(`${process.env.REACT_APP_BACK_URL}/admin/event/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'authorization': `${process.env.REACT_APP_BEARER_TOKEN}${token}`, // Add your authorization token

        },
        // You can include a request body for DELETE if necessary
        // body: JSON.stringify({}),
      });

      if (response.status === 204) {
        // Deletion successful
        setErr("")
        setShowDeleteModal(false);
        // You can perform additional actions or update UI as needed
        setUpdateCount((prevCount) => prevCount + 1);
      } else {
        setErr(response.data.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setErrorList(error.response?.data?.validationError || []);
      setErr(error.response?.data?.message || 'An error occurred');
    }

  }



  //  getEventData --------------------------------------------------------------------


  function getEventData(e) {

    const { name, value, files, type, checked } = e.target;
    // console.log({files});
    if (type === "checkbox") {
      setEvent({
        ...event,
        [name]: checked,
      });
    }
    else if (name === 'mainImage') {
      setEvent({
        ...event,
        mainImage: [...event.mainImage, files[0]],
      });
    } else if (name === 'subImage') {
      // console.log({files});
      setEvent({
        ...event,
        subImage: [files]
      });
    } else {
      setEvent({
        ...event,
        [name]: value,
      });
    }

    console.log({ event });

  }


  //  form submitions --------------------------------------------------------------------

  function submitForm(e) {
    e.preventDefault();
    setIsLoading(true);
    // Perform any additional validation or processing before calling postApi
    let validationResult = validation();

    if (validationResult.error) {
      setIsLoading(false);
      setErrorList(validationResult.error.details);
    } else {
      setErrorList([]);
      postApi(event);
    }
  }




  function updateForm(e) {
    e.preventDefault();
    setIsLoading(true);
    // Perform any additional validation or processing before calling postApi
    let validationResult = updateValidation();

    if (validationResult.error) {
      setIsLoading(false);
      setErrorList(validationResult.error.details);
    } else {
      setErrorList([]);
      // console.log("ok");
      putApi(event);
    }
  }

  // Validation functions  -----------------------------------------


  function validation() {
    const schema = joi.object({

      title: joi.string().min(2).max(500).required(),
      subTitle: joi.string().min(2).max(500),
      // -------
      description: joi.string().max(15000),
      startDate: joi.date().required(),
      endDate: joi.date().required().greater(joi.ref('startDate')).message('End date must be greater than start date'),
      // file: joi.object({
      mainImage: joi.array().items().length(1).required(),
      subImage: joi.array().items().min(0),
      // }).required(),
      type: joi.string().valid("Event", "Workshop").required(),
      isFeatured: joi.boolean().required()

    });

    return schema.validate(event, { abortEarly: true });

  }




  function updateValidation() {
    const schema = joi.object({
      _id: joi.string(),
      title: joi.string().min(2).max(500),
      subTitle: joi.string().min(2).max(500),
      // -------
      description: joi.string().max(15000),
      startDate: joi.date(),
      endDate: joi.date().greater(joi.ref('startDate')).message('End date must be greater than start date'),
      // file: joi.object({
      mainImage: joi.array().items(),
      subImage: joi.array().items().min(0),
      // }),
      type: joi.string().valid("Event", "Workshop"),
      isFeatured: joi.boolean()

    });

    return schema.validate(event, { abortEarly: true });

  }


  const removeSubImage = (index) => {
    // console.log({ index });
    const updatedSubImages = Object.values(event.subImage[0]) ? [...Object.values(event.subImage[0])] : [];
    updatedSubImages.splice(index, 1);
    setEvent({
      ...event,
      subImage: [updatedSubImages], // Use square brackets to wrap the array
    });
    // console.log(event);
  };

  // console.log({ events });



  //  handle Modal -------------------------------------------------------------

  const handleShowAddModal = () => setShowAddModal(true);
  const handleHideAddModal = () => {
    setShowAddModal(false);
    setErr("");
    setErrorList([]);
  }
  const handleHideUpdateModal = () => {
    setShowUpdateModal(false);
    setErr("");
    setEvent({
      title: "",
      subTitle: "",
      description: "",
      startDate: "",
      endDate: "",
      mainImage: [],
      subImage: [],
      type: "",
      isFeatured: false,  
    })
    setErrorList([]);
  }
  const handleShowUpdateModal = (item) => {
    setSelectedItem(item);
    setShowUpdateModal(true);
    // console.log({ item });
    const startDateObject = new Date(item.startDate);
    const endDateObject = new Date(item.endDate);

    const formattedStartDate = startDateObject.toISOString().slice(0, 19).replace('T', ' ');
    const formattedEndDate = endDateObject.toISOString().slice(0, 19).replace('T', ' ');

    // Set the initial state with the selected item's data
    setEvent({
      _id: item._id || '',
      title: item.title || '',
      subTitle: item.subTitle || '',
      description: item.description || '',
      startDate: formattedStartDate || '',
      endDate: formattedEndDate || '',
      mainImage: Array.isArray(item.mainImage) ? item.mainImage : [],
      subImage: Array.isArray(item.subImage) ? item.subImage : [],
      type: item.type || '',
      isFeatured: item.isFeatured || false,
    });
  };

  const handleAddItem = () => {
    if (errList.length == 0 && err != "") {
      handleHideAddModal();
    }
  };

  const handleUpdateItem = () => {
    if (errList.length == 0 && err != "") {
      handleHideUpdateModal();
    }
  };


  const filteredEvents = events.filter((item) =>
    item.title && item.title.toLowerCase().includes(searchTerm?.toLowerCase())
  );





  //  handle Delete Event -------------------------------------------------------------

  const handleDeleteConfirmation = (id) => {
    setItemToDelete(id);
    setShowDeleteModal(true);
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setItemToDelete(null);
  };

  const handleDeleteConfirm = () => {
    if (itemToDelete) {

      deleteApi(itemToDelete);

      setShowDeleteModal(false);
      setItemToDelete(null);
    }
  };





  return (

    <div className="container mt-5">
      <div className="d-flex justify-content-between mb-3">
        <input
          type="text"
          placeholder="Search..."
          className="form-control w-75"
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button className="btn btn-dark" onClick={handleShowAddModal}>
          <i className="fa-solid fa-plus"></i> Add Event
        </button>

      </div>

      <table className="table table-striped">
        <thead>
          <tr>
            <th></th>
            <th>Title</th>
            <th>Subtitle</th>
            <th>Main Image</th>
            <th>Sub Images</th>
            <th></th>

          </tr>
        </thead>
        <tbody>
          {filteredEvents.map((item, index) => (
            <tr key={item._id} >
              <td>{index + 1}</td>
              <td>{item.title}</td>
              <td>{item.subTitle}</td>
              <td> <img src={item.mainImage.secure_url} alt="" width={100} height={100} /></td>
              <td className='w-50'>
                {item?.subImage.map((img, index) => (
                  <a key={index} href={img.secure_url} target="_blank" rel="noopener noreferrer">
                    <img src={img.secure_url} alt="" width={70} height={70} className='mx-1 mb-2' />
                  </a>
                ))}
              </td>
              <td >
                <button
                  className="btn mr-2"
                  onClick={() => handleShowUpdateModal(item)}
                >
                  <i className="fa-regular fa-pen-to-square"></i>
                </button>
                <button
                  className="btn "
                  onClick={() => handleDeleteConfirmation(item._id)}
                >
                  <i className="fa-solid fa-trash" style={{ color: "#ca4e4e" }}></i>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Add Modal */}
      <form onSubmit={submitForm}>
        <div
          className={`modal fade ${showAddModal ? 'show' : ''}`}
          style={{ display: showAddModal ? 'block' : 'none' }}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden={!showAddModal}
        >

          <div className="overlay">
            <div className=" w-50" role="document">

              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Add Event
                  </h5>
                  <button
                    type="button"
                    className="btn bg-danger"
                    onClick={handleHideAddModal}
                  >
                    <i className="fa-solid fa-xmark" style={{ color: "#fff" }}></i>
                  </button>
                </div>
                <div className="modal-body">
                  {err.length > 0 ? <p className='alert alert-danger '>{err}</p> : ""}
                  {errList?.map((item, index) => <p className='alert alert-danger' key={index}>{item.message}</p>)}

                  <div className="row">
                    <div className="col-md-6">
                      <label htmlFor="title">Title</label>
                      <input type="text" id='title' name='title' value={event.title} onChange={getEventData} className='form-control my-2' />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="subTitle">Subtitle</label>
                      <input type="text" id='subTitle' name='subTitle' value={event.subTitle} onChange={getEventData} className='form-control my-2' />

                    </div>
                  </div>
                  <label htmlFor="description">Description</label>
                  <textarea type="text" id='description' name='description' value={event.description} onChange={getEventData} className='form-control my-2' />
                  <div className="row">
                    <div className="col-md-6">
                      <label htmlFor="startDate">Start Date</label>
                      <input type="datetime-local" id='startDate' name='startDate' value={event.startDate} onChange={getEventData} className='form-control my-2' />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="endDate">End Date</label>
                      <input type="datetime-local" id='endDate' name='endDate' value={event.endDate} onChange={getEventData} className='form-control my-2' />

                    </div>

                  </div>

                  <label htmlFor="mainImage">Main Image</label>
                  <input type="file" id='mainImage' name='mainImage' onChange={getEventData} className='form-control my-2' />

                  <label htmlFor="subImage">SubImages</label>
                  <input multiple type="file" id='subImage' name='subImage' onChange={getEventData} className='form-control my-2' />

                  {event?.subImage.length > 0 ?

                    <div>
                      <h4>Selected SubImages:</h4>
                      <ul>
                        {Object.values(event.subImage[0])?.map((subimage, index) => (
                          <li key={index}>
                            {subimage.name}
                            <button
                              type="button"
                              onClick={() => removeSubImage(index)}
                              className="btn btn-sm mx-2"
                            >
                              <i className="fa-solid fa-trash" style={{ color: "#ca4e4e" }}></i>
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                    : ""}

                  <div className="d-flex justify-content-between">
                    <div className="type w-50 ">
                      <select
                        id="type"
                        name="type"
                        value={event.type}
                        onChange={getEventData}
                        className="form-control my-2"
                      >
                        <option value="">Select Type</option>
                        <option value="Event">Event</option>
                        <option value="Workshop">Workshop</option>
                      </select>
                    </div>

                    <div className="check w-50 align-self-center ps-4">
                      <input
                        type="checkbox"
                        id="isFeatured"
                        name="isFeatured"
                        value={event.isFeatured}
                        onChange={getEventData}
                        checked={event.isFeatured}
                        className="form-check-input"
                      />
                      <label htmlFor="isFeatured" className="form-check-label ps-3">
                        Is Featured
                      </label>
                    </div>

                  </div>




                  {/* <label htmlFor="type">Type</label> */}





                  {/* <button className='btn btn-info float-end mt-4'>Register</button> */}

                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleHideAddModal}
                  >
                    Close
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={handleAddItem}
                  >
                    {isLoading == true ? <i className='fas fa-spinner fa-spin'></i> : "Add Event"}

                  </button>
                </div>
              </div>
            </div>
          </div>

        </div>

      </form>
      {/* Update Modal */}

      <form onSubmit={updateForm}>
        <div
          className={`modal fade ${showUpdateModal ? 'show' : ''}`}
          style={{ display: showUpdateModal ? 'block' : 'none' }}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden={!showUpdateModal}
        >
          <div className="overlay">
            <div className=" w-50" role="document">

              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Update Event
                  </h5>
                  <button
                    type="button"
                    className="btn bg-danger"
                    onClick={handleHideUpdateModal}
                  >
                    <i className="fa-solid fa-xmark" style={{ color: "#fff" }}></i>
                  </button>
                </div>
                <div className="modal-body">
                  {err?.length > 0 ? <p className='alert alert-danger '>{err}</p> : ""}
                  {errList?.map((item, index) => <p className='alert alert-danger' key={index}>{item.message}</p>)}

                  <div className="row">
                    <div className="col-md-6">
                      <label htmlFor="title">Title</label>
                      <input type="text" id='title' name='title' value={event.title} onChange={getEventData} className='form-control my-2' />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="subTitle">Subtitle</label>
                      <input type="text" id='subTitle' name='subTitle' value={event.subTitle} onChange={getEventData} className='form-control my-2' />

                    </div>
                  </div>
                  <label htmlFor="description">Description</label>
                  <textarea type="text" id='description' name='description' value={event.description} onChange={getEventData} className='form-control my-2' />
                  <div className="row">
                    <div className="col-md-6">
                      <label htmlFor="startDate">Start Date</label>
                      <input type="datetime-local" id='startDate' name='startDate' value={event.startDate || ''} onChange={getEventData} className='form-control my-2' />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="endDate">End Date</label>
                      <input type="datetime-local" id='endDate' name='endDate' value={event.endDate || ''} onChange={getEventData} className='form-control my-2' />

                    </div>

                  </div>

                  <label htmlFor="mainImage">Main Image</label>
                  <input type="file" id='mainImage' name='mainImage' onChange={getEventData} className='form-control my-2' />

                  <label htmlFor="subImage">SubImages</label>
                  <input multiple type="file" id='subImage' name='subImage' onChange={getEventData} className='form-control my-2' />

                  {
                    // event?.subImage && event.subImage.length > 0 ?

                    //   <div>
                    //     <h4>Selected SubImages:</h4>
                    //     <ul>
                    //       {Object.values(event.subImage[0])?.map((subimage, index) => (
                    //         <li key={index}>
                    //           {subimage.name}
                    //           <button
                    //             type="button"
                    //             onClick={() => removeSubImage(index)}
                    //             className="btn btn-sm mx-2"
                    //           >
                    //             <i className="fa-solid fa-trash" style={{ color: "#ca4e4e" }}></i>
                    //           </button>
                    //         </li>
                    //       ))}
                    //     </ul>
                    //   </div>
                    //   : ""
                  }

                  <div className="d-flex justify-content-between">
                    <div className="type w-50 ">
                      <select
                        id="type"
                        name="type"
                        value={event.type}
                        onChange={getEventData}
                        className="form-control my-2"
                      >
                        <option value="">Select Type</option>
                        <option value="Event">Event</option>
                        <option value="Workshop">Workshop</option>
                      </select>
                    </div>

                    <div className="check w-50 align-self-center ps-4">
                      <input
                        type="checkbox"
                        id="isFeatured"
                        name="isFeatured"
                        checked={event.isFeatured}
                        onChange={getEventData}
                        className="form-check-input"
                      />
                      <label htmlFor="isFeatured" className="form-check-label ps-3">
                        Is Featured
                      </label>
                    </div>

                  </div>


                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleHideUpdateModal}
                  >
                    Close
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={handleUpdateItem}
                  >
                    {isLoading == true ? <i className='fas fa-spinner fa-spin'></i> : "Update Event"}
                  </button>

                </div>
              </div>
            </div>
          </div>
        </div>
      </form>


      {/* Delete Confirmation Modal */}
      <div
        className={`modal fade ${showDeleteModal ? 'show' : ''}`}
        style={{ display: showDeleteModal ? 'block' : 'none' }}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="deleteConfirmationModalLabel"
        aria-hidden={!showDeleteModal}
      >
        <div className="overlay">
          <div className="w-25" role="document">
            <div className="modal-content">
              {err.length > 0 ? <p className='alert alert-danger '>{err}</p> : ""}
              {errList?.map((item, index) => <p className='alert alert-danger' key={index}>{item.message}</p>)}
              <div className="modal-header">
                <h5 className="modal-title" id="deleteConfirmationModalLabel">
                  Delete Confirmation
                </h5>
                <button
                  type="button"
                  className="btn bg-danger"
                  onClick={handleDeleteCancel}
                >
                  <i className="fa-solid fa-xmark" style={{ color: '#fff' }}></i>
                </button>
              </div>
              <div className="modal-body">
                <p>Are you sure you want to delete this event?</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleDeleteCancel}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-danger"
                  onClick={handleDeleteConfirm}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div >
  );
};


