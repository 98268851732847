import React, { useContext, useEffect, useState } from 'react'
import joi from "joi"
import axios from 'axios'
import { BrandsContext } from '../../context/brand.js'



export default function Brand() {


  let { AllBrandsArr, setUpdateCount } = useContext(BrandsContext);
  let [isLoading, setIsLoading] = useState(false);
  const [brandsArr, setNewsArr] = useState([]);
  let [err, setErr] = useState("")
  let [errList, setErrorList] = useState([])
  // console.log({ AllBrandsArr });
  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  // const [updateCount, setUpdateCount] = useState(0);
  const token = localStorage.getItem("token")

  useEffect(() => {
    if (AllBrandsArr && AllBrandsArr.brands) {
      setNewsArr(AllBrandsArr.brands);
    }
  }, [AllBrandsArr])



  let [brand, setBrand] = useState({

    name: "",
    image: [],

  });


  async function postApi(brand) {
    try {
      const postData = new FormData();
      postData.append('name', brand.name);
      brand.image.forEach((image) => {
        postData.append('image', image);
      });
      // console.log({brand});
      const response = await axios.post(`${process.env.REACT_APP_BACK_URL}/admin/brand`, postData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Set the content type for FormData
          'authorization': `${process.env.REACT_APP_BEARER_TOKEN}${token}`, // Add your authorization token
        },
      });
      // console.log({response});
      if (response.data.message === 'success') {
        setErr('');
        setIsLoading(false);
        handleHideAddModal(); // Close the modal on successful submission
        setUpdateCount((prevCount) => prevCount + 1);
      } else {
        setIsLoading(false);
        setErr(response.data.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
      setErrorList(error.response?.data?.validationError || []);
      setErr(error.response?.data?.message || 'An error occurred');
    }
  }



  async function putApi(brand) {
    try {
      const postData = new FormData();
      postData.append('name', brand.name);

      brand.image.forEach((image) => {
        postData.append('image', image);
      });

      // console.log({ postData });

      const response = await axios.put(`${process.env.REACT_APP_BACK_URL}/admin/brand/${brand._id}`, postData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'authorization': `${process.env.REACT_APP_BEARER_TOKEN}${token}`
        },
      });

      if (response.data.message === 'success') {
        setErr('');
        setIsLoading(false);
        handleHideUpdateModal(); // Close the modal on successful submission
        setUpdateCount((prevCount) => prevCount + 1);
      } else {
        setErr(response.data.message);
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching data:', error);
      setErrorList(error.response?.data?.validationError || []);
      setErr(error.response?.data?.message || 'An error occurred');
    }
  }


  async function deleteApi(id) {

    try {
      const response = await axios.delete(`${process.env.REACT_APP_BACK_URL}/admin/brand/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'authorization': `${process.env.REACT_APP_BEARER_TOKEN}${token}`
        },
        // You can include a request body for DELETE if necessary
        // body: JSON.stringify({}),
      });

      if (response.status === 204) {
        // Deletion successful
        setErr("")
        setShowDeleteModal(false);
        // You can perform additional actions or update UI as needed
        setUpdateCount((prevCount) => prevCount + 1);
      } else {
        setErr(response.data.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setErrorList(error.response?.data?.validationError || []);
      setErr(error.response?.data?.message || 'An error occurred');
    }

  }



  //  getBrandData --------------------------------------------------------------------


  function getBrandData(e) {

    const { name, value, files } = e.target;
    // console.log({files});
    if (name === 'image') {
      setBrand({
        ...brand,
        image: [...brand.image, files[0]],
      });
    } else {
      setBrand({
        ...brand,
        [name]: value,
      });
    }

    // console.log({ brand });

  }


  //  form submitions --------------------------------------------------------------------

  function submitForm(e) {
    e.preventDefault();
    setIsLoading(true);
    // Perform any additional validation or processing before calling postApi
    let validationResult = brandValidation();
    // console.log({ validationResult });
    if (validationResult.error) {
      setIsLoading(false);
      setErrorList(validationResult.error.details);
    } else {
      setErrorList([]);
      postApi(brand);
    }
  }




  function updateForm(e) {
    e.preventDefault();
    setIsLoading(true);
    // Perform any additional validation or processing before calling postApi
    let validationResult = brandUpdateValidation();

    if (validationResult.error) {
      setIsLoading(false);
      setErrorList(validationResult.error.details);
    } else {
      setErrorList([]);
      // console.log("ok");
      putApi(brand);
    }
  }

  // Validation functions  -----------------------------------------


  function brandValidation() {
    const schema = joi.object({

      name: joi.string().min(1).max(100).required(),
      image: joi.array().items().length(1).required(),

    });

    return schema.validate(brand, { abortEarly: true });

  }




  function brandUpdateValidation() {
    const schema = joi.object({
      _id: joi.string(),
      name: joi.string().min(1).max(100),
      image: joi.array().items().max(1),

    });

    return schema.validate(brand, { abortEarly: true });

  }





  //  handle Modal -------------------------------------------------------------

  const handleShowAddModal = () => setShowAddModal(true);
  const handleHideAddModal = () => {
    setShowAddModal(false);
    setErr("");
    setErrorList([]);
  }
  const handleHideUpdateModal = () => {
    setShowUpdateModal(false);
    setErr("");
    setBrand({
      name: "",
      image: [],
    })
    setErrorList([]);
  }
  const handleShowUpdateModal = (item) => {
    setSelectedItem(item);
    setShowUpdateModal(true);
    // console.log({ item });

    // Set the initial state with the selected item's data
    setBrand({
      _id: item._id || '',
      name: item.name || '',
      image: Array.isArray(item.image) ? item.image : [],
    });
  };

  const handleAddItem = () => {
    if (errList.length == 0 && err != "") {
      handleHideAddModal();
    }
  };

  const handleUpdateItem = () => {
    if (errList.length == 0 && err != "") {
      handleHideUpdateModal();
    }
  };


  const filteredBrands = brandsArr.filter((item) =>
    item.name && item.name.toLowerCase().includes(searchTerm?.toLowerCase())
  );





  //  handle Delete Event -------------------------------------------------------------

  const handleDeleteConfirmation = (id) => {
    setItemToDelete(id);
    setShowDeleteModal(true);
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setItemToDelete(null);
  };

  const handleDeleteConfirm = () => {
    if (itemToDelete) {

      deleteApi(itemToDelete);

      setShowDeleteModal(false);
      setItemToDelete(null);
    }
  };





  return (

    <div className="container mt-5">
      <div className="d-flex justify-content-between mb-3">
        <input
          type="text"
          placeholder="Search..."
          className="form-control w-75"
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button className="btn btn-dark" onClick={handleShowAddModal}>
          <i className="fa-solid fa-plus"></i> Add Brand
        </button>

      </div>
      <p className="text-danger">Logos have to be an <span className="fw-bold">SVG</span> file with a transparent background, however it is recommended to contact the developer before adding new brands.</p>

      <table className="table table-striped">
        <thead>
          <tr>
            <th></th>
            <th>Brand Name</th>
            <th>Logo</th>
            <th></th>

          </tr>
        </thead>
        <tbody>
          {filteredBrands.map((item, index) => (
            <tr key={item._id} >
              <td>{index + 1}</td>
              <td>{item.name}</td>
              <td> <img src={item.image.secure_url} alt="" width={100} height={100} /></td>

              <td >
                <button
                  className="btn mr-2"
                  onClick={() => handleShowUpdateModal(item)}
                >
                  <i className="fa-regular fa-pen-to-square"></i>
                </button>
                <button
                  className="btn "
                  onClick={() => handleDeleteConfirmation(item._id)}
                >
                  <i className="fa-solid fa-trash" style={{ color: "#ca4e4e" }}></i>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Add Modal */}
      <form onSubmit={submitForm}>
        <div
          className={`modal fade ${showAddModal ? 'show' : ''}`}
          style={{ display: showAddModal ? 'block' : 'none' }}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden={!showAddModal}
        >

          <div className="overlay">
            <div className=" w-50" role="document">

              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Add Brand
                  </h5>
                  <button
                    type="button"
                    className="btn bg-danger"
                    onClick={handleHideAddModal}
                  >
                    <i className="fa-solid fa-xmark" style={{ color: "#fff" }}></i>
                  </button>
                </div>
                <div className="modal-body">
                  {err.length > 0 ? <p className='alert alert-danger '>{err}</p> : ""}
                  {errList?.map((item, index) => <p className='alert alert-danger' key={index}>{item.message}</p>)}

                  <div className="row">
                    <div className="col-md-12">
                      <label htmlFor="name">Brand Name</label>
                      <input type="text" id='name' name='name' onChange={getBrandData} className='form-control my-2' />
                    </div>
                  </div>

                  <label htmlFor="image">Brand Image</label>
                  <input type="file" id='image' name='image' onChange={getBrandData} className='form-control my-2' />

                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleHideAddModal}
                  >
                    Close
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={handleAddItem}
                  >
                    {isLoading == true ? <i className='fas fa-spinner fa-spin'></i> : "Add Brand"}

                  </button>
                </div>
              </div>
            </div>
          </div>

        </div>

      </form>
      {/* Update Modal */}



      <form onSubmit={updateForm}>
        <div
          className={`modal fade ${showUpdateModal ? 'show' : ''}`}
          style={{ display: showUpdateModal ? 'block' : 'none' }}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden={!showUpdateModal}
        >
          <div className="overlay">
            <div className=" w-50" role="document">

              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Update Brand
                  </h5>
                  <button
                    type="button"
                    className="btn bg-danger"
                    onClick={handleHideUpdateModal}
                  >
                    <i className="fa-solid fa-xmark" style={{ color: "#fff" }}></i>
                  </button>
                </div>
                <div className="modal-body">
                  {err?.length > 0 ? <p className='alert alert-danger '>{err}</p> : ""}
                  {errList?.map((item, index) => <p className='alert alert-danger' key={index}>{item.message}</p>)}

                  <div className="row">
                    <div className="col-md-12">
                      <label htmlFor="name">Brand Name</label>
                      <input type="text" id='name' name='name' value={brand.name} onChange={getBrandData} className='form-control my-2' />
                    </div>
                  </div>
                  <label htmlFor="image">Brand Image</label>
                  <input type="file" id='image' name='image' onChange={getBrandData} className='form-control my-2' />

                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleHideUpdateModal}
                  >
                    Close
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={handleUpdateItem}
                  >

                    {isLoading == true ? <i className='fas fa-spinner fa-spin'></i> : "Update Brand"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>


      {/* Delete Confirmation Modal */}
      <div
        className={`modal fade ${showDeleteModal ? 'show' : ''}`}
        style={{ display: showDeleteModal ? 'block' : 'none' }}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="deleteConfirmationModalLabel"
        aria-hidden={!showDeleteModal}
      >
        <div className="overlay">
          <div className="w-25" role="document">
            <div className="modal-content">
              {err.length > 0 ? <p className='alert alert-danger '>{err}</p> : ""}
              {errList?.map((item, index) => <p className='alert alert-danger' key={index}>{item.message}</p>)}
              <div className="modal-header">
                <h5 className="modal-title" id="deleteConfirmationModalLabel">
                  Delete Confirmation
                </h5>
                <button
                  type="button"
                  className="btn bg-danger"
                  onClick={handleDeleteCancel}
                >
                  <i className="fa-solid fa-xmark" style={{ color: '#fff' }}></i>
                </button>
              </div>
              <div className="modal-body">
                <p>Are you sure you want to delete this Brand?</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleDeleteCancel}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-danger"
                  onClick={handleDeleteConfirm}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div >
  );
};


