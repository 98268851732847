import './App.css';
import { RouterProvider, createBrowserRouter } from "react-router-dom"
import Root from './Root.jsx';
import Event from './components/Event/Event.jsx';
import Contact from './components/Contact/Contact.jsx';
import News from './components/News/News.jsx';
import Subscriber from './components/Subscriber/Subscriber.jsx';
import Profile from './components/Profile/Profile.jsx';
import Login from './components/Login/Login.jsx';
import Notfound from './components/Notfound/Notfound.jsx';
import { useEffect, useState } from 'react';
import jwtDecode from "jwt-decode"
import ProtectedRoute from "./components/protectedRoute/ProtectedRoute.jsx";
import Brand from './components/Brand/Brand.jsx';
function App() {


  useEffect(() => {
    if (localStorage.getItem('token') !== null) {
      saveCurrentUser()
    }
  }, [])




  let [user, setUser] = useState(null);
  function saveCurrentUser() {
    let encode = localStorage.getItem("token");
    let decoded = jwtDecode(encode);
    setUser(decoded);

  }



  let routes = createBrowserRouter([
    {
      path: "/", element: <Root user={user} setUser={setUser} ></Root>, children: [
        { path: "event", element: <ProtectedRoute user={user}><Event /></ProtectedRoute> },
        { path: "brand", element: <ProtectedRoute user={user}><Brand /></ProtectedRoute> },
        { path: "contact", element: <ProtectedRoute user={user}><Contact /></ProtectedRoute> },
        { path: "news", element: <ProtectedRoute user={user}><News /></ProtectedRoute> },
        { path: "subscriber", element: <ProtectedRoute user={user}><Subscriber /></ProtectedRoute> },
        { path: "profile", element: <ProtectedRoute user={user}><Profile /></ProtectedRoute> },
        { path: "login", element: <Login saveCurrentUser={saveCurrentUser} /> },
        { index: true, element: <ProtectedRoute user={user}><Event saveCurrentUser={saveCurrentUser} /></ProtectedRoute> },
        { path: "*", element: <Notfound></Notfound> }
      ]
    }
  ])

  return (
    <>

      <RouterProvider router={routes}></RouterProvider>
    </>
  )
}

export default App;
